import React, { useEffect } from "react"
import { globalHistory } from "@reach/router";
import { navigate } from 'gatsby'

const NotFoundPage = () => {
  const langFromQuery = globalHistory?.location.pathname.split('/')[1];

  useEffect(() => {
    langFromQuery === 'en' || langFromQuery === 'ua' || langFromQuery === 'ru' ?
      navigate(`/${langFromQuery}/not-found`, { replace: false }) :
      navigate(`/en/not-found`, { replace: false })
  }, [])

  return (
    <div></div>
  )
}


export default NotFoundPage
